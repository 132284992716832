import React, {Component} from 'react';
import About from './About';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';

class Footer extends Component {
    render() {
        return (
            <div className="fixed-bottom">
                <Navbar bg="dark" variant="dark" className="justify-content-center">
                    Made by <a href="https://abalmasov.com">Artem</a>. Logo created with ♥ in <a href="http://forelsketstudio.com/" target="_blank" rel="noopener">Forelsket Studio</a>. © 2020 All rights Reserved.
                </Navbar>
            </div>
        );
    }
}


export default Footer;