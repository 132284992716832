import React, {Component} from 'react';
import About from './About';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';

class Header extends Component {



    render() {
        return (
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="justify-content-lg-center">
                <Navbar.Brand href="#">
                    <img
                        alt=""
                        src="/litgame_logo.png"
                        width="35"
                        height="35"
                        className="d-inline-block align-top"
                    />{' '}
                    LitGam.Act0
                </Navbar.Brand>

                <Nav className="mr-sm-2">
                    <About />
                </Nav>

            </Navbar>
        );
    }
}


export default Header;