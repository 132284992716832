import React, {Component} from 'react';
import logo from './logo.svg';
import './App.css';

class App extends Component {
    constructor(props) {
        super(props);

        this.textLog = React.createRef();
    }
    state = {
        history: [],
        intentions: [],
        counter: 0
    }

    componentDidMount() {
        fetch('/api/turn')
            .then(res => res.json())
            .then(data => {
                var modernHistory = this.state.history.concat(data["history"])
                this.setState({
                    history: modernHistory,
                    intentions: data["intentions"],
                    counter: this.state.counter+1
                })
                this.scrollToBottom();
                // console.log(this.state)
            })
            .catch(console.log)
    }

    makeTurn(action) {
        fetch('/api/turn?action=' + action)
            .then(res => res.json())
            .then(data => {
                var modernHistory = this.state.history.concat(data["history"])
                this.setState({
                    history: modernHistory,
                    intentions: data["intentions"],
                    counter: this.state.counter+1
                })

                console.log(this.state)
            })
            .catch(console.log)
    }
    componentDidUpdate() {
        this.textLog.current.scrollTop = this.textLog.current.scrollHeight;
    }
    render() {
        return (
            <div className="App">
                <div>
                    <textarea ref={this.textLog}  rows="19" cols="75" disabled={true} value={this.state.history.map(hi=>hi+"\n").join("")}>
                        {this.state.history.map(his =>
                            <p>{his}</p>)}
                    </textarea>
                </div>
                <ul key = {this.state.counter}>
                    {this.state.intentions.map(intent =>
                        <li key={intent.key} >
                                <a href="#" data-toggle="tooltip" title={intent.tooltip}
                                   onClick={() => {this.makeTurn(intent.key)}}>
                                    {intent.name} ({intent.cost})
                                </a>
                            </li>
                    )}
                </ul>
            </div>
        );
    }
}

export default App;
