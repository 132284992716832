import React, {useState} from 'react';

import {Modal, Button} from 'react-bootstrap';
import {Nav} from 'react-bootstrap';

function About() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


        return (
            <>
                <Nav.Link onClick={handleShow}>About</Nav.Link>

                <Modal show={show} onHide={handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>About</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><p> Some time ago, I fell for GameLit as a genre.
                        It was a    great way to spend time, but many main characters felt overpowered.</p>
                        <p>I thought, what if we could play video games as reading favorite GameLit book? It would be awesome.
                            Current video games don't have flexibility and auto-balancing I crave, so I started this small project. I hope to evolve it into true crafting/dungeon crawl MMORPG with AI-balancing eventually.  </p>
                        <p>Act 0 is an endless line of rooms on a 'spiritual' plane, so health/mana/stamina stats are combined in one energy. You can craft your gear or destroy it to turn into energy! Just remember to defeat a monster guarding a room and loot a chest.
                            Traveling spirit will encounter endless combinations of loot and monsters until it incarnates into a body in Act1.</p>
                        <p>Keep in touch - any questions/ideas are welcome at project's facebook page -
                            <a href="https://www.facebook.com/LitGam">LitGam</a>.</p>
                        <p>Regards, <a href="https://abalmasov.com/">Artem</a></p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
}

export default About;